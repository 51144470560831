import React, { useState } from "react";

import useScrollHide from "./Header/useScrollHide";
import Nav, { Props } from "./Header/Nav";

export default function Header(props: Props) {
  const scrollHide = useScrollHide(64);

  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);

  const classNames = ["top-header"];
  if (showMenu) {
    classNames.push("show-menu");
  } else if (scrollHide) {
    classNames.push("hidden");
  }

  return (
    <header className={classNames.join(" ")}>
      <div className="inner">
        <a href="/" className="logo">
          Krystallklart budskap
        </a>
        <button
          aria-expanded={showMenu}
          aria-controls="top-nav"
          className="top-nav-toggle"
          onClick={toggleMenu}>
          {!showMenu ? "Meny" : "Lukk"}
        </button>
        <Nav {...props} />
      </div>
    </header>
  );
}

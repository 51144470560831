import React from "react";

interface Props {
  page: NavPage;
  current: boolean;
}

export default function NavItem(props: Props) {
  const { page, current } = props;

  const classNames = [];
  if (current) {
    classNames.push("current");
  }

  return (
    <li>
      <a href={page.url} className={classNames.join(" ")}>
        {page.name}
      </a>
    </li>
  );
}

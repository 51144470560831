import { useEffect, useRef, useState } from "react";

import {
  addViewportObserver,
  removeViewportObserver
} from "../../lib/viewportObserver";

export default function useScrollHide(threshold: number): boolean {
  const scrollRef = useRef<number>();

  const [scrollHide, setScrollHide] = useState(false);

  useEffect(() => {
    const updateScroll = (offset: number) => {
      if (typeof scrollRef.current === "number") {
        const delta = offset - scrollRef.current;
        if (Math.abs(delta) >= threshold) {
          scrollRef.current = offset;
          if (delta > 0) {
            setScrollHide(true);
          } else {
            setScrollHide(false);
          }
        }
      } else {
        scrollRef.current = offset;
      }
    };

    const id = addViewportObserver({ scroll: updateScroll });
    return () => {
      removeViewportObserver(id);
    };
  }, [threshold]);

  return scrollHide;
}

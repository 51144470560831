import React from "react";

import NavItem from "./NavItem";

export interface Props {
  currentRoot?: number;
  pages: NavPage[];
  searchUrl: string;
}

export default function Nav(props: Props) {
  const { currentRoot, pages, searchUrl } = props;
  return (
    <nav id="top-nav" className="top-nav">
      <ul>
        {pages.map((p) => (
          <NavItem key={p.id} page={p} current={p.id == currentRoot} />
        ))}
        <li>
          <a className="search" href={searchUrl}>
            Søk
          </a>
        </li>
      </ul>
    </nav>
  );
}

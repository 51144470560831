type ViewportResizeHandler = (width: number, height: number) => void;
type ViewportScrollHandler = (
  offset: number,
  width: number,
  height: number
) => void;

type ViewportObserver = {
  resize?: ViewportResizeHandler;
  scroll?: ViewportScrollHandler;
};

type ViewportObserverId = number;

let currentId: ViewportObserverId = 0;
const observers: Record<ViewportObserverId, ViewportObserver> = {};

let triggerQueued = false;
let docHeight: number = null;
let windowWidth: number = null;
let windowHeight: number = null;
let scrollOffset: number = null;

function animationFrameLoop() {
  if (Object.keys(observers).length > 0) {
    handleViewportObservers();
    requestAnimationFrame(animationFrameLoop);
  }
}

function handleViewportObservers() {
  const os = Object.values(observers);
  let nextDocHeight = 0;
  if (document.body && document.body.scrollHeight) {
    nextDocHeight = document.body.scrollHeight;
  }

  // Viewport resize
  if (
    triggerQueued ||
    windowWidth != window.innerWidth ||
    windowHeight != window.innerHeight ||
    docHeight != nextDocHeight
  ) {
    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;
    docHeight = nextDocHeight;
    scrollOffset = null;

    os.filter((o) => o.resize).forEach((observer) => {
      observer.resize(windowWidth, windowHeight);
    });

    triggerQueued = false;
  }

  // Scroll
  if (scrollOffset != window.scrollY) {
    scrollOffset = window.scrollY;
    os.filter((o) => o.scroll).forEach((observer) => {
      observer.scroll(scrollOffset, windowWidth, windowHeight);
    });
  }
}

export function addViewportObserver(
  observer: ViewportObserver
): ViewportObserverId {
  currentId += 1;
  observers[currentId] = observer;
  animationFrameLoop();
  return currentId;
}

export function removeViewportObserver(id: ViewportObserverId) {
  delete observers[id];
}

export function triggerViewportObservers() {
  triggerQueued = true;
}
